import { HTTP_OK } from 'constants/httpStatusConstants';
import FileForm from 'components/atoms/form/FileForm';
import HiddenForm from 'components/atoms/form/HiddenForm';
import InputForm from 'components/atoms/form/InputForm';
import SelectForm from 'components/atoms/form/SelectForm';
import TextAreaForm from 'components/atoms/form/TextAreaForm';
import { InputType } from 'components/atoms/form/base/BaseFormPartInterface';
import CheckBoxFormSet from 'components/shareds/form/CheckBoxFormSet';
import FormField from 'components/shareds/form/FormField';
import { useFlashMessageState } from 'contexts/FlashMessageContext';
import { useCallApi } from 'hooks/useCallApi';
import parse from 'html-react-parser';
import { SyntheticEvent, ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { ApiRequestInterface, CustomFormRequest } from 'types/apiRequests';
import { ApiResponseErrorType, ApiResponseType } from 'types/apiResponseTypes';

// TODO: textfield/input/fieldsetあたりはSymfony時代の名残の型なので消したい
type CustomFormSetFieldType = InputType | 'markup' | 'textfield' | 'fieldset' | 'input' | 'textarea' | 'select';

type CustomFormSetItemType = {
  type: CustomFormSetFieldType;
  title: string;
  name: string;
  require: boolean;
  placeholder: string;
  description: string;
  showLabel: boolean;
  markup: string;
  numberStep: string | null;
  defaultValue?: string | number;
  items: {
    name: string;
    value: number;
  }[];
  isMultipleSelect?: boolean;
};

interface CustomFormSetInterface {
  items: CustomFormSetItemType[];
  children?: ReactElement;
  request?: ApiRequestInterface;
  errors?: ApiResponseErrorType;
  willSubmitForm?: (target: HTMLElement, customFormData: FormData) => void;
  didSubmitForm?: (target: HTMLElement, customFormData: FormData, response: ApiResponseType<any> | null) => void;
  didLoadForm?: (target: HTMLElement, customFormData: FormData) => void;
  didChangeForm?: (target: HTMLElement, customFormData: FormData) => void;
};

export const SwitchableForm = (props: { item: CustomFormSetItemType }): React.ReactElement => {
  const item = props.item;

  switch(item.type) {
  case 'text':
  case 'email':
  case 'number':
  case 'date':
  case 'input':
  case 'textfield':
    return (
      <InputForm
        value={item.defaultValue}
        type={item.type as InputType}
        name={item.name}
        placeholder={item.placeholder}
      />
    );
  case 'textarea':
    return (
      <TextAreaForm
        value={item.defaultValue}
        name={item.name}
        placeholder={item.placeholder}
      />
    );
    // selectに統一
  case 'radio':
  case 'checkbox':
  case 'select':
    if (item.isMultipleSelect) {
      return (
        <CheckBoxFormSet
          name={`${item.name}[]`}
          checkBoxes={item.items.map((checkBox) => (
            { label: checkBox.name, value: checkBox.value }
          ))}
        />
      );
    }
    return (
      <SelectForm
        name={item.name}
      >
        {item.items.map((option, i) => (
          <option key={i} value={option.value}>{option.name}</option>
        ))}
      </SelectForm>
    );
  case 'file':
    return (
      <FileForm
        name={item.name}
      />
    );
  case 'fieldset':
    return <h3>{item.title}</h3>
  case 'markup':
    return <>{parse(item.markup ?? '')}</>;
  default:
    return <></>
  }
}

const CustomFormSet = (props: CustomFormSetInterface) => {
  const ref = useRef(null);
  const { callApi } = useCallApi(),
    { setFlashMessage } = useFlashMessageState(),
    [errors, setErrors] = useState<ApiResponseErrorType>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const formData = new FormData(ref.current as unknown as HTMLFormElement);
    props.didLoadForm?.(ref.current!, formData);
  }, [ref.current])

  useEffect(() => {
    if (props.errors) {
      setErrors(props.errors);
    }
  }, [props.errors]);

  const onChangeEvent = (e: ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget as HTMLFormElement);
    props.didChangeForm?.(e.currentTarget, formData);
  };

  const fillEmptyFile = async (formData: FormData) => {
    await Promise.all(props.items.map(item => {
      if (item.type === 'file') {
        const file = formData.get(item.name) as File;

        if (file.size < 1) {
          formData.set(item.name, '')
        }
      }
      return null;
    }));

    return formData;
  }

  /* eslint-disable max-statements */
  const onSubmitEvent = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget as HTMLFormElement);
    await fillEmptyFile(formData);

    props.willSubmitForm?.(e.currentTarget, formData);

    let response: ApiResponseType<any> | null = null
    if (props.request) {
      response = await callApi(new CustomFormRequest({
        formData,
        request: props.request
      }));
      if (response) {
        setErrors(response.errors);
        setFlashMessage({
          color: response.status === HTTP_OK ? 'primary' : 'danger',
          message: response.message ?? ''
        });
      }
    }
    props.didSubmitForm?.(e.currentTarget, formData, response);
  }
  /* eslint-enable max-statements */

  return (
    <form
      ref={ref}
      onChange={onChangeEvent}
      onSubmit={onSubmitEvent}
    >
      {props.items.map((item, i) => {
        if (item.type === 'hidden') {
          return (
            <HiddenForm
              key={i}
              value={item.defaultValue}
              name={item.name}
            />
          )
        }

        const fieldName = item.type === 'fieldset' ? undefined : item.title;
        const required = ['fieldset', 'markup'].includes(item.type) ? undefined : item.require;

        return (
          <FormField
            key={i}
            fieldName={fieldName}
            required={required}
            description={item.description}
            errors={errors?.[item.name]}
          >
            <SwitchableForm item={item} />
          </FormField>
        )
      })}
      {props.children}
    </form>
  );
}

export default CustomFormSet;
export type { CustomFormSetItemType, CustomFormSetInterface, CustomFormSetFieldType };
