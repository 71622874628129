import styled from 'styled-components';
import { BaseFormPartInterface } from 'components/atoms/form/base/BaseFormPartInterface';
import { BaseInput } from 'components/atoms/form/base/BaseInput';

const HiddenFormStyled = styled.div`
  display: 'none';
`;

const HiddenForm = (props: BaseFormPartInterface): React.ReactElement => (
  <HiddenFormStyled>
    <BaseInput {...props} type='hidden' />
  </HiddenFormStyled>
);

export default HiddenForm;
