import CheckBoxForm from 'components/atoms/form/CheckBoxForm';
import { useState } from 'react';
import styled from 'styled-components';
import { fontSize } from 'styles/mixins';

const CheckBoxFormSetStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

const CheckBoxFormStyled = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  gap: 10px;

  label {
    flex-shrink: 0;
  }

  .label {
    ${fontSize(14)}
  }
`

export type CheckBoxFormValue = number | string;

interface CheckBoxFormInterface {
    checkedValues?: CheckBoxFormValue[];
    name: string;
    checkBoxes: { value: CheckBoxFormValue; label: string; }[];
    disabled?: boolean;
    didClickEachPart?: (checkedValues: CheckBoxFormValue[]) => void;
}

const CheckBoxFormSet = (props: CheckBoxFormInterface): React.ReactElement => {
  const [checkedValues, setCheckedValues] = useState(props.checkedValues ?? [])

  return (
    <CheckBoxFormSetStyled>
      {(checkedValues.length < 1) && (
        // 何も選択しなかった場合（任意のチェックボックスの場合）に何も送られないのでその回避
        <input type='hidden' name={props.name} />
      )}
      {props.checkBoxes.map((checkBox, i) => (
        <CheckBoxFormStyled
          key={i}
          className='check-box-set'
        >
          <CheckBoxForm
            name={props.name}
            value={checkBox.value}
            checked={checkedValues.includes(checkBox.value)}
            disabled={props.disabled}
            didClickPart={(_, part) => {
              const index = checkedValues.indexOf(part.value!);
              if (index < 0) {
                checkedValues.push(part.value!);
              } else {
                checkedValues.splice(index, 1);
              }
              setCheckedValues([...checkedValues]);
              props.didClickEachPart?.(checkedValues)
            }}
          />
          <span className='label'>{checkBox.label}</span>
        </CheckBoxFormStyled>
      ))}
    </CheckBoxFormSetStyled>
  );
}

export default CheckBoxFormSet;
