import styled from 'styled-components';
import { fontSize } from 'styles/mixins';
import { grayScales } from 'styles/variables';
import { BaseInput } from 'components/atoms/form/base/BaseInput';
import { BaseFormPartInterface } from 'components/atoms/form/base/BaseFormPartInterface';

const FileFormStyled = styled.div`
  border: 1px solid ${grayScales.gs300};
  box-sizing: border-box;
  ${fontSize(16)}
  width: 100%;

  input {
    ${fontSize(16)}
    padding: 20px 16px;
  }
`;

const FileForm = (props: BaseFormPartInterface): React.ReactElement => {
  const getNewProps = (target: HTMLElement, item: BaseFormPartInterface) => {
    const file = (target as HTMLInputElement).files?.[0];
    const { ...newProps } = item;
    Object.assign(newProps, { file });
  
    return newProps;
  }

  return (
    <FileFormStyled>
      <BaseInput
        {...props}
        type='file'
        didClickPart={(target, item) => {
          props.didClickPart?.(target, getNewProps(target, item))
        }}
        didChangePart={(target, item) => {
          props.didChangePart?.(target, getNewProps(target, item))
        }}
      />
    </FileFormStyled>
  );
}

export default FileForm;
