import { ChangeEvent, MouseEvent, useState } from 'react';
import { BaseFormPartInterface, InputValueType } from 'components/atoms/form/base/BaseFormPartInterface';

export const BaseTextArea = <T extends BaseFormPartInterface>(props: T) => {
  const [currentValue, setCurrentValue] = useState<InputValueType>(props.value || '');

  const event = (
    e: ChangeEvent<HTMLTextAreaElement> | MouseEvent,
    callback?: (target: HTMLElement, part: T) => void
  ) => {
    if (props.disabled) {
      return;
    }

    const target = e.target as HTMLTextAreaElement;
    setCurrentValue(target.value);

    const { ...newProps } = props;
    newProps.value = target.value;

    callback?.(target, newProps);
  }

  return <textarea
    value={currentValue}
    name={props.name}
    disabled={props.disabled}
    placeholder={props.placeholder}
    onClick={(e) => event(e, props.didClickPart)}
    onChange={(e) => event(e, props.didChangePart)}
  />
}
