import styled from 'styled-components';
import { fontSize } from 'styles/mixins';
import { BaseFormPartInterface } from 'components/atoms/form/base/BaseFormPartInterface';
import { BaseInput } from 'components/atoms/form/base/BaseInput';

const CheckBoxFormStyled = styled.label`
  ${fontSize(14)}
  display: inline-block;
  height: 20px;
  position: relative;
  width: 20px;

  input {
    opacity: 0;
    position: absolute;

    :checked + span.check-mark::after {
      background-color: #0087d9;
      content: '';
      display: inline-block;
      height: 14px;
      margin: 4px;
      position: absolute;
      width: 14px;
    }
  }

  ::before {
    background-color: #fff;
    border: 1px solid #bdbfc7;
    content: '';
    height: 100%;
    position: absolute;
    width: 100%;
  }
`;

const CheckBoxForm = (props: BaseFormPartInterface): React.ReactElement => (
  <CheckBoxFormStyled>
    <BaseInput {...props} type='checkbox' />
    <span className='check-mark' />
  </CheckBoxFormStyled>
);

export default CheckBoxForm;
