import { ChangeEvent, MouseEvent, useState } from 'react';
import { BaseFormPartInterface } from 'components/atoms/form/base/BaseFormPartInterface';

export const BaseInput = <T extends BaseFormPartInterface>(props: T) => {
  const [checked, setChecked] = useState(props.checked ?? false);

  const event = (
    e: ChangeEvent<HTMLInputElement> | MouseEvent,
    callback?: (target: HTMLElement, part: T) => void
  ) => {
    if (props.disabled) {
      return;
    }

    const target = e.target as HTMLInputElement;
    setChecked(target.checked)

    const { ...newProps } = props;
    newProps.value = target.value;
    newProps.checked = target.checked;

    callback?.(target, newProps);
  }

  return <input
    value={props.value}
    name={props.name}
    type={props.type ?? 'text'}
    defaultChecked={checked}
    disabled={props.disabled}
    placeholder={props.placeholder}
    onClick={(e) => event(e, props.didClickPart)}
    onChange={(e) => event(e, props.didChangePart)}
    onBlur={props.onBlur}
    onFocus={props.onFocus}
    onKeyDown={props.onKeyDown}
  />
}
