import styled from 'styled-components';
import { fontSize } from 'styles/mixins';
import { grayScales } from 'styles/variables';
import { BaseTextArea } from 'components/atoms/form/base/BaseTextArea';
import { BaseFormPartInterface } from './base/BaseFormPartInterface';

const TextAreaFormStyled = styled.span`
  textarea {
    border: 1px solid ${grayScales.gs300};
    box-sizing: border-box;
    ${fontSize(16)}
    padding: 20px 16px;
    width: 100%;

    ::placeholder {
      color: ${grayScales.gs400};
    }
  }
`;

const TextAreaForm = (props: BaseFormPartInterface): React.ReactElement => (
  <TextAreaFormStyled>
    <BaseTextArea {...props} />
  </TextAreaFormStyled>
);

export default TextAreaForm;
