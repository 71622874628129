import styled from 'styled-components';
import { fontSize } from 'styles/mixins';
import { colors, grayScales } from 'styles/variables';
import parse from 'html-react-parser';

const FormFieldStyled = styled.div`
  .header {
    display: flex;
    margin: 15px 0;
    align-items: center;
    gap: 5px;

    label {
      ${fontSize(8)}
      color: ${colors.white};
      padding: 4px 8px;
      white-space: nowrap;

      &.required {
        background-color: ${colors.danger};
      }

      &.optional {
        background-color: ${grayScales.gs400};
      }
    }

    span {
      ${fontSize(16)}
    }
  }

  .description {
    ${fontSize(12)}
    color: ${grayScales.gs600};
    margin: 10px 0;
  }

  .error-message {
    ${fontSize(14)}
    color: red;
    margin: 5px 0;
  }
`;

interface FormFieldInterface {
    children: React.ReactElement;
    required?: boolean;
    fieldName?: string;
    description?: string;
    errors?: string[];
}

const FormField = (props: FormFieldInterface) => (
  <FormFieldStyled>
    {(props.required || props.fieldName) && (
      <div className='header'>
        { props.required !== undefined && (
          <label className={props.required ? 'required' : 'optional'}>
            {props.required ? '必須' : '任意'}
          </label>
        )}
        { props.fieldName && <span>{props.fieldName}</span> }
      </div>
    )}
    <div className='body'>
      {props.children}
    </div>
    {props.description && (
      <div className='description'>
        {parse(props.description)}
      </div>
    )}
    {props.errors && (
      props.errors.map((error, i) => (
        <div key={i} className='error-message'>{error}</div>
      ))
    )}
  </FormFieldStyled>
);

export default FormField;
export type { FormFieldInterface };
