import styled from 'styled-components';
import { fontSize } from 'styles/mixins';
import { grayScales } from 'styles/variables';
import { BaseFormPartInterface } from 'components/atoms/form/base/BaseFormPartInterface';
import { BaseInput } from 'components/atoms/form/base/BaseInput';

const InputFormStyled = styled.div`
  display: flex;

  input {
    box-sizing: border-box;
    border: 1px solid ${grayScales.gs300};
    ${fontSize(16)}
    padding: 20px 16px;
    width: 100%;

    ::placeholder {
      color: ${grayScales.gs400};
    }
  }

  i {
    ${fontSize(16)}

    border: 1px solid ${grayScales.gs300};
    color: ${grayScales.gs600};
    padding: 20px 16px;
  }

  &.has-symbol {
    input {
      border-left: none;
    }

    i {
      border-right: none;
      text-align: right;
      white-space: nowrap;
    }
  }

  &.no-symbol {
    i {
      display: none;
    }
  }
`;

interface InputFormInterface extends BaseFormPartInterface {
  symbol?: string;
  step?: string;
};

const InputForm = (props: InputFormInterface): React.ReactElement => (
  <InputFormStyled className={props.symbol ? 'has-symbol' : 'no-symbol'}>
    <i className='input-form--symbol'>{props.symbol}</i>
    <BaseInput {...props} />
  </InputFormStyled>
);

export default InputForm;
