import styled from 'styled-components';
import { TiArrowSortedDown } from 'react-icons/ti';
import { fontSize } from 'styles/mixins';
import { colors, grayScales } from 'styles/variables';
import { BaseSelect, BaseSelectInterface } from 'components/atoms/form//base/BaseSelect';

const SelectFormStyled = styled.span`
  align-items: center;
  display: flex;
  justify-content: end;
  position: relative;

  .arrow {
    ${fontSize(24)}
    background-color: ${colors.white};
    position: absolute;
    padding: 5px;
    right: 5px;
  }

  select {
    ${fontSize(16)}
    border: solid 1px ${grayScales.gs300};
    border-radius: 2px;
    box-sizing: border-box;
    padding: 20px 26px 20px 16px;
    width: 100%;
  }
`;

const SelectForm = (props: BaseSelectInterface): React.ReactElement => (
  <SelectFormStyled>
    <TiArrowSortedDown className='arrow' />
    <BaseSelect {...props}>
      {props.children}
    </BaseSelect>
  </SelectFormStyled>
);

export default SelectForm;
