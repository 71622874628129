import { ChangeEvent, MouseEvent, useState } from 'react';
import { BaseFormPartInterface, InputValueType } from 'components/atoms/form/base/BaseFormPartInterface';

export interface BaseSelectInterface extends BaseFormPartInterface {
    children?: React.ReactElement[]
}

export const BaseSelect = <T extends BaseSelectInterface>(props: T) => {
  const [currentValue, setCurrentValue] = useState<InputValueType>(props.value || '');

  const event = (
    e: ChangeEvent<HTMLSelectElement> | MouseEvent,
    callback?: (target: HTMLElement, part: T) => void
  ) => {
    if (props.disabled) {
      return;
    }

    const target = e.target as HTMLSelectElement;
    setCurrentValue(target.value);

    const { ...newProps } = props;
    newProps.value = target.value;

    callback?.(target, newProps);
  }

  return <select
    value={currentValue}
    name={props.name}
    disabled={props.disabled}
    placeholder={props.placeholder}
    onClick={(e) => event(e, props.didClickPart)}
    onChange={(e) => event(e, props.didChangePart)}
  >
    {props.children}
  </select>
}
